import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "How to remove the SD Card in the camera models IN-9010 FHD and IN-9020 FHD",
  "path": "/Frequently_Asked_Question/9020_and_9010_Remove_SD_Card/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "Where do I find the SD card in my IN-9020 Full HD?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='How to remove the SD Card in the camera models IN-9010 FHD and IN-9020 FHD' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Where do I find the SD card in my IN-9020 Full HD?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/9020_and_9010_Remove_SD_Card/' locationFR='/fr/Frequently_Asked_Question/9020_and_9010_Remove_SD_Card/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "how-to-remove-the-sd-card-in-the-camera-models-in-9010-fhd-and-in-9020-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-to-remove-the-sd-card-in-the-camera-models-in-9010-fhd-and-in-9020-fhd",
        "aria-label": "how to remove the sd card in the camera models in 9010 fhd and in 9020 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to remove the SD Card in the camera models IN-9010 FHD and IN-9020 FHD`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Where do I find the SD card in my IN-9020 Full HD? I need to do a factory reset with my camera.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Open the small hatch on the back of the camera head and use your tweezers to remove the MicroSD Card from the card reader:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50ebb8bb92cf53d48e945806cd669beb/64f73/IN-9020FHD_SD_Card.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB6ly6pJgH/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAIDARIj/9oACAEBAAEFArU89prladPOjqUoz6f/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIREBIhIjFh/9oACAEBAAY/AlKD2L1OvRtdFKRyd4//xAAcEAADAAEFAAAAAAAAAAAAAAAAAREQITFRYXH/2gAIAQEAAT8hh21BV6ERDHlrYh7UlwakYx//2gAMAwEAAgADAAAAEDcP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QGf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQADAAIDAAAAAAAAAAAAAAEAESEQgUFRof/aAAgBAQABPxBqvCWF4+al001sgHtmKHjdYZOuAk+xqHLBcOuP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50ebb8bb92cf53d48e945806cd669beb/e4706/IN-9020FHD_SD_Card.avif 230w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/d1af7/IN-9020FHD_SD_Card.avif 460w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/7f308/IN-9020FHD_SD_Card.avif 920w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/44a65/IN-9020FHD_SD_Card.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50ebb8bb92cf53d48e945806cd669beb/a0b58/IN-9020FHD_SD_Card.webp 230w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/bc10c/IN-9020FHD_SD_Card.webp 460w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/966d8/IN-9020FHD_SD_Card.webp 920w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/cb8de/IN-9020FHD_SD_Card.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50ebb8bb92cf53d48e945806cd669beb/e83b4/IN-9020FHD_SD_Card.jpg 230w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/e41a8/IN-9020FHD_SD_Card.jpg 460w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/4d836/IN-9020FHD_SD_Card.jpg 920w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/64f73/IN-9020FHD_SD_Card.jpg 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50ebb8bb92cf53d48e945806cd669beb/4d836/IN-9020FHD_SD_Card.jpg",
              "alt": "Reset your INSTAR IP Camera",
              "title": "Reset your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you want to format the SD card on the PC, please select the `}<strong parentName="p">{`FAT32`}</strong>{` format.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      